defineDs('DanskeSpil/Domain/Feature.Navigation/Scripts/Components/NavigationToggleMenus', ['Common/Framework/EventHandling/Scripts/CrossWindowEvents'], function (CrossWindowEvents) {
  const focusableElements = 'a[href], button:not([disabled]), textarea, input, select, [tabindex]:not([tabindex="-1"])';

  const setFocus = (menu) => {
    // Update tabindex to make them focusable now that the menu is open
    menu.querySelectorAll(focusableElements)
      .forEach((f) => f.setAttribute('tabindex', '0'));
  };

  const removeFocus = (menu) => {
    // Reset tabindex to make elements unfocusable again
    menu.querySelectorAll(focusableElements)
      .forEach((f) => f.setAttribute('tabindex', '-1'));
  };

  const open = (menu) => {
    menu.classList.remove('close');
    menu.classList.add('open');
    setFocus(menu);
    CrossWindowEvents.fire('ds.event.balanceUpdated');
    document.dispatchEvent(new CustomEvent('overlay:navigation:show'));
  };

  const close = (menu) => {
    menu.classList.remove('open');
    menu.classList.add('close');
    removeFocus(menu);
    document.dispatchEvent(new CustomEvent('overlay:navigation:hide'));
  };

  const initialize = () => {
    // Event listeners
    document.addEventListener('navigation:menu:open', ({ detail: { menu } }) => {
      open(menu);
    });

    document.addEventListener('navigation:menu:close', ({ detail: { menu } }) => {
      close(menu);
    });
  };

  initialize();

});

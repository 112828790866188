defineDs('DanskeSpil/Domain/Feature.Navigation/Scripts/Components/NavigationBurgerMenu',
  [
    'Common/Framework/EventHandling/Scripts/CrossWindowEvents',
  ], function (CrossWindowEvents) {
    const menu = document.querySelector('.js-navigation-burger-menu');

    if (!menu) {
      return;
    }

    const menuItems = menu.querySelectorAll('.ds-navigation-burger-menu__item-with-sub-items');
    const openMenuToggle = document.querySelector('.js-navigation-open-burger-menu');
    const closeMenuToggle = menu.querySelector('.js-navigation-close-burger-menu');

    const closeAllSubMenus = () => {
      menuItems?.forEach((item) => {
        item.classList.remove('open'); // Remove active class from the menu item
      });
    };
    const openMenu = async () => {
      document.dispatchEvent(new CustomEvent('navigation:menu:open', { detail: { menu } }));
      CrossWindowEvents.fire('ds.event.balanceUpdated');
    };

    const closeMenu = () => {
      closeAllSubMenus();
      document.dispatchEvent(new CustomEvent('navigation:menu:close', { detail: { menu } }));
    };

    const initialize = () => {
      menuItems?.forEach((item) => {
        item.querySelector('button').addEventListener('click', function (e) {
          this.parentElement.classList.toggle('open');
          e.stopPropagation(); // Prevent this click from propagating to the document level
        });
      });

      // Add event listeners
      openMenuToggle?.addEventListener('click', openMenu);
      closeMenuToggle?.addEventListener('click', closeMenu);
      document.addEventListener('navigation:burgerMenu:close', closeMenu);
    };

    initialize();

  });


